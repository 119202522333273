import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { Dialog, DialogContent } from "@mui/material";
import cartazJamanca from "../Photos/cartazJamaca1.jpg";
const HomePage = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Container>
      <Row className="p-3" lg={"2"} xs={"1"}>
        <Col className="pb-3">
          <h1>Jamanca #1</h1>
          <h3>O que é o Jamanca?</h3>
          <p>
            O Jamanca é um projeto que visa dar vida à cultura musical de
            Avanca, criando um espaço aberto à expressão artística e ao encontro
            de músicos de todas as idades e níveis de experiência. A nossa
            proposta é simples: permitir que todos, independentemente do
            conhecimento ou instrumento, possam partilhar a sua paixão pela
            música, colaborar, improvisar e divertir-se. O nosso objetivo é
            fazer de Avanca um ponto de encontro de talentos e ideias, onde a
            música flui naturalmente, sem barreiras.
          </p>

          <div
            className={expanded ? "full-text-xs" : "max-lines-xs"}
            onClick={toggleExpanded}
          >
            <h3>Data e Local do Evento</h3>
            <p>
              A primeira edição do Jamanca acontecerá no dia
              <b> 8 de março, na Cervejaria D. Duarte</b>, um local acolhedor e
              perfeito para a celebração da música e da cultura. Durante este
              evento, haverá à disposição
              <b>
                {" "}
                duas guitarras, um baixo e uma bateria para todos os
                participantes
              </b>{" "}
              que quiserem se juntar à jam session. Mas, atenção: se tocas algum
              instrumento de sopro, não hesites em trazê-lo! O Jamanca é para
              todos, e quanto mais diversidade musical, melhor!
            </p>
            <h3>Uma Oportunidade para Todos</h3>
            <p>
              Se tens curiosidade em aprender, criar ou apenas ouvir, vem fazer
              parte deste momento único. Não importa o teu nível de experiência
              – a ideia é divertir-se, partilhar e fazer crescer a cultura
              musical local. O Jamanca é para quem tem vontade de explorar, de
              se conectar com outros músicos e de contribuir para um ambiente
              descontraído.
            </p>
            <h5>
              Marca já no calendário: 8 de março, Cervejaria D. Duarte.
              Esperamos por ti para a primeira grande jam de Avanca!
            </h5>
          </div>
        </Col>
        <Col style={{ display: "inline-block" }} className="pb-3">
          <React.Suspense fallback={<></>}>
            <img
              alt=""
              style={{ width: "100%" }}
              src={cartazJamanca}
              loading="lazy"
              onClick={() => setSelectedImage(cartazJamanca)}
            />
          </React.Suspense>
        </Col>
      </Row>
      <Dialog
        open={selectedImage !== null}
        onClose={() => setSelectedImage(null)}
      >
        <DialogContent
          style={{
            padding: "0px",
            overflow: "hidden",
          }}
        >
          {selectedImage && (
            <img alt="" src={cartazJamanca} style={{ width: "100%" }} />
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default HomePage;
