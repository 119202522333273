import * as React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom"; // Import NavLink for active link styling
import logo from "../Photos/logo.png"; // Import logo image

/**
 * Funciona como uma nav bar colada ao topo
 * da web app, quando é feito o resize para telemovel
 * fica com um menu de opções.
 *
 * Ao clicar em qualquer um dos links é redirecionado
 * para o local da pagina onde esta uma div com esse id.
 */
function Header() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        sticky="top"
        style={{ backgroundColor: "var(--body-bg)" }}
        variant="dark"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              alt="Logo"
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            AVANCA GARE
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="responsive-nav-bar">
            <Nav className="me-auto">
              {/* Using NavLink to highlight active link */}
              <Nav.Link
                as={NavLink}
                to="/edicoesanteriores"
                activeClassName="active"
              >
                Edições Anteriores
              </Nav.Link>
              <Nav.Link as={NavLink} to="/galeria" activeClassName="active">
                Galeria
              </Nav.Link>
              <Nav.Link as={NavLink} to="/bandas" activeClassName="active">
                Artistas e Bandas
              </Nav.Link>
              <Nav.Link as={NavLink} to="/organizacao" activeClassName="active">
                Organização e Apoio
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
