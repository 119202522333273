import * as React from "react";
import { Dialog, DialogContent, ImageList, ImageListItem } from "@mui/material";
import { useState } from "react";

/**
 *
 * @returns Galeria de Fotografias
 */
const Photos = ({ itemData = [] }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <ImageList
        sx={{
          paddingTop: "2%",
        }}
        cols={3}
      >
        {itemData
          ? itemData.map((item) => (
              <ImageListItem key={item}>
                <img
                  alt=""
                  src={item}
                  loading="lazy"
                  onClick={() => handleImageClick(item)}
                />
              </ImageListItem>
            ))
          : null}
      </ImageList>

      <Dialog open={selectedImage !== null} onClose={handleClose}>
        <DialogContent
          style={{
            padding: "0px",
            overflow: "hidden",
          }}
        >
          {selectedImage && (
            <img alt="" src={selectedImage} style={{ width: "100%" }} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Photos;
