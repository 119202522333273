import React from "react";

import Header from "./componentes/Header";
import Cartaz from "./componentes/Cartaz";
import Footer from "./componentes/Footer";

import Verao23 from "./componentes/edicoes/verao23";
import Inverno23 from "./componentes/edicoes/inverno23";
import Verao24 from "./componentes/edicoes/verao24";

import Organizacao from "./componentes/Organizacao";
import HomePage from "./componentes/HomePage";
import Photos from "./componentes/Photos";
import Bandas from "./componentes/Bandas";

//Galeria
import photo2 from "./Photos/2.jpg";
import photo3 from "./Photos/3.jpg";
import photo4 from "./Photos/4.jpg";
import photo6 from "./Photos/6.jpg";
import photo7 from "./Photos/7.jpg";
import photo8 from "./Photos/8.jpg";
import photo9 from "./Photos/9.jpg";
import photo11 from "./Photos/11.jpg";
import photo12 from "./Photos/12.jpg";
import ovar6 from "./Photos/Ovar/ovar6.jpg";
import ovar7 from "./Photos/Ovar/ovar7.jpg";
import ovar8 from "./Photos/Ovar/ovar8.jpg";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";

function App() {
  const itemData = [
    photo2,
    photo3,
    photo4,
    photo6,
    photo7,
    photo8,
    photo9,
    photo11,
    photo12,
    ovar6,
    ovar8,
    ovar7,
  ];

  return (
    <div className="App">
      <div className="main">
        <BrowserRouter>
          <Routes>
            {/* Common routes with Header and Footer */}
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Cartaz />
                  {/* Outlet for nested routes */}
                  <Outlet />
                  <Footer />
                </>
              }
            >
              <Route path="/" element={<HomePage />} />
              <Route
                path="/edicoesanteriores"
                element={
                  <Container>
                    <Verao24 />
                    <Inverno23 />
                    <Verao23 />
                  </Container>
                }
              />
              <Route path="/bandas" element={<Bandas />} />
              <Route path="/organizacao" element={<Organizacao />} />
              <Route
                path="/galeria"
                element={
                  <>
                    <h1
                      sx={{
                        paddingTop: "2%",
                      }}
                    >
                      Galeria
                    </h1>
                    <Photos itemData={itemData}></Photos>
                  </>
                }
              />
            </Route>
            {/* Special route without Header and Footer */}
          </Routes>
        </BrowserRouter>
        {
          //AlertCopied(alert)
        }
      </div>
    </div>
  );
}

export default App;
