import React from "react";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";
import { HiOutlineMail } from "react-icons/hi";

/**
 * Footer da aplicação, com o contacto, uma mensagem
 * e um botão com redirect para o paypal para contribuições
 */
function Footer() {
  return (
    <>
      <div className="footer">
        <Container className="pt-5 pb-3">
          <Card.Title className="pb-3">
            Este projeto é independente.
            <br />
            Qualquer tipo de ajuda faz a diferença. Obrigado por apoiarem o Rock
            Português.
            <br />
          </Card.Title>
          <Card.Text class="col text-center">
            <HiOutlineMail />
            geral@avancagare.pt
            <br />
          </Card.Text>
        </Container>
      </div>
    </>
  );
}

export default Footer;
