import React from "react";
import logo from "../Photos/logo.png";
import logoJamanca from "../Photos/JAMAVANCA-LOGO.png";
import { BsInstagram, BsSpotify, BsYoutube } from "react-icons/bs";
export default function Cartaz() {
  return (
    <div id="cartaz">
      <div>
        <React.Suspense fallback={<></>}>
          <img alt="" src={logo} id="logo" />{" "}
          <img alt="" src={logoJamanca} id="logo" />{" "}
        </React.Suspense>
      </div>
      <div>
        <a
          rel="noopener noreferrer"
          href="https://open.spotify.com/user/31byr5ckokwcbpepvp5yppwsderi?si=15b2a92751144b3c"
          target="_blank"
          id="icon"
        >
          <BsSpotify size={30}></BsSpotify>
        </a>
        <a
          rel="noopener noreferrer"
          href="http://www.instagram.com/avancagare"
          target="_blank"
          id="icon"
        >
          <BsInstagram size={30} className="m-3"></BsInstagram>
        </a>
        <a
          rel="noopener noreferrer"
          href="https://www.youtube.com/@AvancaGare"
          target="_blank"
          id="icon"
        >
          <BsYoutube size={35} />
        </a>
      </div>
    </div>
  );
}
