import Card from 'react-bootstrap/Card';
import { BsInstagram } from 'react-icons/bs';
import { BsSpotify } from 'react-icons/bs';
import { BsYoutube } from 'react-icons/bs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';

function BandCard(band) {
  return (
    <Card className='m-3' id={band.name} bg="dark" style={{color:'white',alignItems:'center'}}>
      <Card.Img variant="top" src={band.photo} />
      <Card.Body>
        <Card.Title>{band.name}</Card.Title>
        <Card.Text style={{textAlign:'center'}}>
          {band.description}
        </Card.Text>
        <Container>
          <Row>
            {spotifyrender(band.spotify)}
            {youtuberender(band.youtube)}
            <Col>
              <a rel="noopener noreferrer" href={'http://www.instagram.com/' + band.instagram} target="_blank">
                <BsInstagram size={30}  id="icon" ></BsInstagram>
              </a>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

function spotifyrender(spotify) {
  if (spotify !== undefined) {
    return (
      <Col>
        <a rel="noopener noreferrer" href={spotify} target="_blank" id="icon">
          <BsSpotify size={30} ></BsSpotify>
        </a>
      </Col>
    );
  }
}


function youtuberender(youtube) {
  if (youtube !== undefined) {
    return (
      <Col>
        <a rel="noopener noreferrer" href={youtube} target="_blank" id="icon">
          <BsYoutube size={35}/>
        </a>
      </Col>
    );
  }
}


export default BandCard;